$(function(){
    "use strict";
    var view = $('#view'),
        video = $('#video'),
        headerMain = $('header.main'),
        offcanvas = $('#offcanvas'),
        overlay = $("#overlay_disabled");
    //#####################################################################################EASING
    var EasingFunctions = {
        linear: function (t) { return t },
        easeInQuad: function (t) { return t*t },
        easeOutQuad: function (t) { return t*(2-t) },
        easeInOutQuad: function (t) { return t<.5 ? 2*t*t : -1+(4-2*t)*t },
        easeInCubic: function (t) { return t*t*t },
        easeOutCubic: function (t) { return (--t)*t*t+1 },
        easeInOutCubic: function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 },
        easeInQuart: function (t) { return t*t*t*t },
        easeOutQuart: function (t) { return 1-(--t)*t*t*t },
        easeInOutQuart: function (t) { return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t },
        easeInQuint: function (t) { return t*t*t*t*t },
        easeOutQuint: function (t) { return 1+(--t)*t*t*t*t },
        easeInOutQuint: function (t) { return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t }
    }
    //#####################################################################################CONFIG

    var $GLOBAL_mobile,
        $GLOBAL_localStorage,
        $GLOBAL_ie;
    //mobile detection
    function UAdetect(){
        navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
        ?
            $GLOBAL_mobile = true
        :
            $GLOBAL_mobile = false;
    }
    UAdetect();
    //####################################################################################################Tracker
    var $ID_messageClose;
    if( $GLOBAL_mobile === false ){
        $(document).mouseleave(
            function () {
                if (!sessionStorage.disable_message) {
                    if ($ID_messageClose === undefined || $ID_messageClose === "") {
                        $(".app_message").addClass('active');
                    }
                }
            }
        );

        $(".app_message").find(".btn.close").bind("click", function() {
            $(this).parent().removeClass("active");
            $ID_messageClose = "disabled";
            sessionStorage.disable_message = 1;
        });

    }
    //####################################################################################################IE warning
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        $GLOBAL_ie = 'yes';
    }

    function isIE () {
        var myNav = navigator.userAgent.toLowerCase();
        return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
    }

    var isIE = (navigator.userAgent.indexOf("MSIE") != -1);

    if(isIE == 8 || isIE == 7 || isIE == 6) {
        $GLOBAL_ie = 'outdated v.';
    }else if(isIE == 9){

    }else{
        $GLOBAL_ie = 'no';
    }


    //####################################################################################################localStorage
    if(typeof(Storage)!=="undefined"){
        $GLOBAL_localStorage = 'enabled';
    }else{
        $GLOBAL_localStorage = 'not available';
    }
    //prevent copy
     /*
    document.onmousedown = prevRight;
     function prevRight(event){
        if(event.button==2)
        {
            console.error("%cYou shall not pass!", "color: #ED0000");
            event.preventDefault();
            return false;
        }
    }
    $(document).bind("contextmenu", function (event) {
        console.error("%cYou shall not pass!", "color: #ED0000");
        event.preventDefault();
        return false;
    });
    $('input.disablecopypaste').bind('copy paste', function (event) {
        console.error("%cYou shall not pass!", "color: #ED0000");
        event.preventDefault();
        return false;
    });
    var isCtrl;
    document.onkeydown = function(event){
        if(event.which == 17 || event.which == 224 || event.which == 91 || event.which == 93 ){
            isCtrl = true;
        }
        if((event.which == 85) || (event.which == 67) && isCtrl == true){
            console.error("%cYou shall not pass!", "color: #ED0000");
            event.preventDefault();
            return false;
        }
    };*/

    //INIT App
    //#####################################################################################
    if(!isIE){
        console.debug("Mobile: " + $GLOBAL_mobile + " | IE: " + $GLOBAL_ie + " | localStorage: " + $GLOBAL_localStorage);
        console.log("welcome to %cbe.beyond", "color: #666666");
    }
    //CustomJS#####################################################################################
    var eventHandler;

    $GLOBAL_mobile === true ? eventHandler = "touchstart" : eventHandler = "click";

    //####################################################################################################offcanvas
    $('.btn.offcanvas').add('#offcanvas .btn.close').bind( eventHandler, function(){
        offcanvas.toggleClass("active");
        view.toggleClass("disabled");
        $("body").toggleClass("disabled");
        overlay.toggleClass("active");
    });

    $(document).bind( eventHandler, function(e){

            var target = $(e.target);

        overlay.add('#offcanvas .btn.close').bind('click', function(){
            e.stopPropagation();
            e.preventDefault();
            if(offcanvas.hasClass("active")){
                offcanvas.removeClass('active');
                $("body").removeClass("disabled");
                view.removeClass('disabled');
                $("#overlay_disabled").removeClass("active");
            }
        });
    });

    offcanvas.customScrollbar({
        updateOnWindowResize : true,
        hScroll : false,
        animationSpeed: 100,
        fixedThumbHeight: 300,
        wheelSpeed: 80,
        preventDefaultScroll: false,
        swipeSpeed: 1,
        setAnimationSpeed: 100
    });

    //#####################################################################################################offcanvas li.menu-item-has-children,
    offcanvas.find('li.menu-item-has-children > a').bind(eventHandler, function(e){
        e.preventDefault();
        $(this).parent().toggleClass("active");
        $(this).siblings("ul.sub-menu").stop().slideToggle( 600, function(){
            $(window).trigger('resize');
        } );
    });


    //####################################################################################################set view header img
    if($(window).width() <= 2500 ){
        var diff = ((2500 - $(window).width()) / 2);
    }else{
        var diff = 0;
    }

    var windowWidth = $(window).width();
    view.find('figure.teaser img').css({'left' : -diff + 'px' });
    view.find('figure.teaser').css({'width' : windowWidth });


    $(window).resize(function(){
        if($(window).width() <= 2500 ){
            diff = ((2500 - $(window).width()) / 2);
        }else{
            diff = 0;
        }
        windowWidth = $(window).width();

        view.find('figure.teaser img').css({'left' : -diff + 'px' });
        view.find('figure.teaser').css({'width' : windowWidth });
    });

    //####################################################################################################Header scrollUpdate
    $(window).scroll(function(e){
        var scrollAmount = $(this).scrollTop();
        scrollAmount >= 160 ? headerMain.addClass("active") : headerMain.removeClass("active");
    });

    //####################################################################################################CHROME FIX - DAMN YOU CHROME!
    if( $GLOBAL_mobile == true ){
        $("body").addClass("mobile");
    }

    //####################################################################################################Setting view-BG COLOR
    function hexToRgb(hex) {
        if(!hex){ return }
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }
    var bgColor = $(".topbar").css("background-color");
    hexToRgb(bgColor);
    var color = bgColor.substring(0, bgColor.length - 1);
    var colorOnly = color.substring(3);
    var opacity = ", 0.25)";
    var newColor = "rgba" + colorOnly + opacity;
    view.css('background-color', newColor);
    video.length > 0 ? video.get(0).play() : false;
    $("#preloading").delay(500).fadeOut(500);
    //toggle footbar
    $('footer.main li.menu-item-has-children > a').bind(eventHandler, function(e){
        e.preventDefault();
        $(this).parent().toggleClass('active');
        $(this).parent().find('ul').stop().slideToggle(500);
    });
    //####################################################################################################Blink menu btn
    var anim_counter = 0;
    function animOffCanvasBtn(count){
        count <= 5 && !sessionStorage.getItem('blink') ? setTimeout(function(){
            $('.btn.offcanvas').toggleClass('active');
            animOffCanvasBtn(anim_counter);
        }, 400) : sessionStorage.setItem('blink',1);
        anim_counter++;
    }
    animOffCanvasBtn(anim_counter);

    //##############################################################################################Blog load more posts

    $('.btn.more.ajax').bind('click', function(e){
        ajaxPageRequest(e);
    });

    function ajaxPageRequest(e){
        var button,
            url;
        e.stopPropagation();
        e.preventDefault();

        button = $(e.target);
        url = e.target.href;

        $.get(url, function(data){

        }).done(function(data){
            var content = false,
                success;

            content = $(data);
            success = content.find('#articles').html();
            $('#articles').append(success);
            button.attr('href',content.find('.btn.more.ajax').attr('href'));
            button.unbind('click');
            button.bind('click', function(e){
                ajaxPageRequest(e);
            });

            content.find('.btn.more.ajax').length > 0 ? button = '' : $('.btn.ajax').remove();
        });
    }
    //##############################################################################################Blog load more posts
    var accordion = $('.accordion'),
        acc_head = accordion.find('.acc_head'),
        acc_content = accordion.find('.acc_content');

    acc_content.first().slideDown(0);
    acc_head.first().addClass('active');
    acc_head.each(function(){
       $(this).bind('click', function(e){
           e.stopPropagation();

           console.log($(this));
           console.log($(this).hasClass('active'));


           if( $(this).hasClass('active') ){
               acc_head.removeClass('active');
               acc_content.slideUp(200);
           }else{
               acc_head.removeClass('active');
               acc_content.slideUp(200);
               $(this).addClass('active');
               $(this).next('.acc_content').slideDown(200);
           }
       });
    });
});